import * as React from 'react';
import {REQUEST_STATES} from "../../consts/request-states";
import Loader from "../Structure/Loader/Loader";
import {withTranslation} from "react-i18next";
import UserState from "./UserState";
import UserDetails from "./UserDetails";
import UserRoles from "./UserRoles";
import UserPassport from "./UserPassport";
import UserDates from "./UserDates";
import UserActions from "./UserActions";

const User = ({user, requestState, error, t}) => {
  if ([REQUEST_STATES.init, REQUEST_STATES.loading].includes(requestState)) {
    return (
      <div className="p-8 w-full h-full flex flex-col justify-center items-center">
        <Loader />
      </div>
    )
  }

  if (requestState === REQUEST_STATES.failed) {
    return (
      <div className="p-8 w-full h-full flex flex-col justify-center items-center">
        <div className="rounded bg-red-200 border-red-700 text-red-700">
          <strong>{t('Something went wrong')}:</strong> {error}
        </div>
      </div>
    )
  }

  return (
    <div className="p-8">
      <UserPassport user={user} />

      <UserActions user={user}/>

      <div className="flex mt-4">
        <div className="w-1/2 mr-2">
          <UserState user={user} />
          <div className="my-4"/>
          <UserDetails user={user} />
        </div>

        <div className="ml-2 w-1/2">
          <UserRoles user={user} />
          <UserDates user={user} />
        </div>
      </div>
    </div>
  )
};

export default withTranslation()(User);