import produce from "immer";
import {createAsyncAction} from "../middlewares/redux-async";
import {REQUEST_STATES} from "../consts/request-states";
import {getUsers} from "../api/gameplay";

export const types = {
  GET_USERS_LOADING: 'users::list::loading',
  GET_USERS_SUCCESS: 'users::list::success',
  GET_USERS_FAILURE: 'users::list::failure',
};

const initialState = {
  requestState: REQUEST_STATES.init,
  users: [],
  filters: [],
  page: 0,
  pageSize: 100,
  count: 0,
  availablePages: 0
};

export default function users(previousState = initialState, action) {
  return produce(previousState, newState => {
    switch (action.type) {
      case types.GET_USERS_LOADING:
        newState.requestState = REQUEST_STATES.loading;
        newState.page = action.payload.page;
        newState.pageSize = action.payload.pageSize;
        newState.filters = action.payload.filters;

        return newState;

      case types.GET_USERS_SUCCESS:
        newState.requestState = REQUEST_STATES.success;
        newState.users = action.payload.results;
        newState.count = action.payload.state.count;
        newState.availablePages = action.payload.state.pages;

        return newState;

      case types.GET_USERS_FAILURE:
        newState.requestState = REQUEST_STATES.failed;
        newState.users = [];
        newState.count = 0;
        newState.availablePages = 0;

        return newState;

      default:
        return newState;
    }
  });
}

export const actions = {
  getUsers: (page, pageSize, filters = []) => createAsyncAction({
    asyncRequest: getUsers.bind(null, page, pageSize, filters),
    requestPayload: {page, pageSize, filters},
    requestType: types.GET_USERS_LOADING,
    successType: types.GET_USERS_SUCCESS,
    failureType: types.GET_USERS_FAILURE,
  }),
};
