import * as React from 'react';
import EnsureLoggedInContainer from "../components/Structure/Auth/EnsureLoggedInContainer/EnsureLoggedInContainer";
import Template from "../components/Structure/Template/Template";
import UserContainer from "../components/User/UserContainer";

const UserScene = props =>
{
  return (
    <EnsureLoggedInContainer>
      <Template>
        <UserContainer userId={props.match.params.id} />
      </Template>
    </EnsureLoggedInContainer>
  )
};

export default UserScene;