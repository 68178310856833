import * as React from 'react';
import {REQUEST_STATES} from "../../consts/request-states";
import Loader from "../Structure/Loader/Loader";
import H2 from "../Structure/Tailwind/H2";
import moment from 'moment';
import {Table, TableCell, TableHeaderCell, TableWrapper} from "../Structure/Table";
import Button from "../Structure/Tailwind/Button";
import {withRouter} from "react-router";
import UserNavigator from "./UserNavigator";
import UsersFilters from "./UsersFilters";

const Users = props => {
  const { users, requestState, page, count } = props.users;

  const isLoading = [REQUEST_STATES.init, REQUEST_STATES.loading].includes(requestState);

  return (
    <div className="p-8">
      <H2>Users <span className="text-gray-500">({count})</span></H2>

      <div className="flex justify-between mb-4">
        <UserNavigator />

        <UsersFilters />
      </div>

      <TableWrapper>
        <Table>
          <TableHeader />
          <tbody>
          {isLoading && <tr>
            <td className="w-full text-center h-64" colSpan="100%">
              <Loader/>
            </td>
          </tr>}

          {!isLoading && users.map(user => <tr key={user.id} onClick={() => props.history.push(`/users/${user.id}`)} className="cursor-pointer">
            <TableCell>{user.username}</TableCell>
            <TableCell>{user.email}</TableCell>
            <TableCell>{user.firstName}</TableCell>
            <TableCell>{user.lastName}</TableCell>
            <TableCell>{user.language}</TableCell>
            <TableCell>{user.registeredViaClient !== undefined ? user.registeredViaClient.name : ''}</TableCell>
            <TableCell>{moment(users.createdAt).fromNow()}</TableCell>
          </tr>)}
          </tbody>
        </Table>
      </TableWrapper>

      <div className="flex flex-row justify-center items-center">
        <div className="sm:w-full md:w-1/6 m-4"><Button onClick={props.handlePreviousPage} disabled={page === 0}>Previous page</Button></div>
        <div className="sm:w-full md:w-1/6 m-4"><Button onClick={props.handleNextPage}>Next page</Button></div>
      </div>
    </div>
  )
};

export const TableHeader = () => (
  <thead>
  <tr className="py-2 px-3 sticky top-0 border-b border-gray-200 bg-gray-100">
    <TableHeaderCell>Username</TableHeaderCell>
    <TableHeaderCell>Email</TableHeaderCell>
    <TableHeaderCell>First name</TableHeaderCell>
    <TableHeaderCell>Last name</TableHeaderCell>
    <TableHeaderCell>Language</TableHeaderCell>
    <TableHeaderCell>Registered via</TableHeaderCell>
    <TableHeaderCell>Created at</TableHeaderCell>
  </tr>
  </thead>
);


export default withRouter(Users);