import * as React from "react";
import {withTranslation} from "react-i18next";

import CenteredContainer from "../CenteredContainer/CenteredContainer";
import Loader from "./Loader";

const CenteredContainerLoader = ({t}) => (
  <CenteredContainer withoutFlex>
    <div className="flex items-center justify-center w-full">
      <Loader/>
    </div>
  </CenteredContainer>
);

export default withTranslation()(CenteredContainerLoader);