import * as React from 'react';
import H2 from "../Structure/Tailwind/H2";

const UserPassport = ({user}) => {
  return (
    <div className="bg-white rounded shadow-xl p-8">
      <H2>{user.username}</H2>
      <hr className="mb-4" />

      <div className="flex">
          <span className="text-gray-700 flex mr-2">
          <img src={require('../../assets/img/icons/envelope.svg')} alt="email" className="w-3 mr-2 fill-current text-gray-300"/>
            {user.email}
        </span>

        <span className="text-gray-700 flex">
          <img src={require('../../assets/img/icons/badge.svg')} alt="id" className="w-3 mr-2 fill-current text-gray-300"/>
          {user.id}
        </span>
      </div>
    </div>
  )
};

export default UserPassport;