import * as React from 'react';
import {connect} from "react-redux";
import User from "./User";
import {actions} from "../../ducks/user";

class UserContainer extends React.Component
{
  componentDidMount()
  {
    this.props.getUser(this.props.userId);
  }

  render()
  {
    return (
      <User {...this.props} />
    );
  }
}

const mapStateToProps = state => ({
  ...state.user
});

const mapDispatchToProps = dispatch => ({
  getUser: userId => dispatch(actions.getUser(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserContainer);