import * as React from 'react';

const ButtonClasses = [
  "rounded-md",
  "bg-blue-100",
  "text-blue-700",
  "px-3",
  "py-1",
  "m-1",
];

const ToggledButton = [
  "rounded-md",
  "bg-blue-500",
  "text-white",
  "px-3",
  "py-1",
  "m-1",
];

const ToggleableButton = props => {
  if (props.isToggled) {
    return (
      <button
        className={ToggledButton.join(' ')}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.children}
      </button>
    );
  }

  return (
    <button
      className={ButtonClasses.join(' ')}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

ToggleableButton.defaultProps = {
  isToggled: false,
  onClick: () => {},
};

export default ToggleableButton;