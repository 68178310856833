import merge from 'merge';
import camelcaseKeys from 'camelcase-keys';

class Client
{
  options = {
    headers: {'Content-Type': 'application/json'}
  };

  preCallMethod = () => {};

  getAuthentication = () => {};

  constructor(options = {}, preCallMethod = () => {}, getAuthentication = () => {}) {
    this.options = options;
    this.preCallMethod = preCallMethod;
    this.getAuthentication = getAuthentication;
  }

  call = (route, options = this.options) => {
    const promiseResult = this.preCallMethod();

    if (promiseResult !== undefined && promiseResult instanceof Promise) {
      return promiseResult.then(() => {return this.executeMethod(route, options)});
    }

    return this.executeMethod(route, options);
  };

  executeMethod = (route, options = this.options) => {
    let mergedOptions = merge.recursive(options, this.options);

    const authenticationResult = this.getAuthentication();

    if (!(authenticationResult instanceof Promise)) {
      mergedOptions.headers['Authorization'] = this.getAuthentication();

      return this.buildRequest(route, mergedOptions);
    }

    return authenticationResult.then(authenticationString => {
      mergedOptions.headers['Authorization'] = authenticationString;

      return this.buildRequest(route, mergedOptions);
    });
  };

  buildRequest = (route, options) => {
    return fetch(`${route}`, options)
      .then(response => {
        if (response.status === 204) {
          return ({statusCode: response.status, json: []});
        }

        return response.json().then(json => ({statusCode: response.status, json: camelcaseKeys(json, {deep: true})}));
      })
      .then(response => {
        return new Promise((resolve, reject) => {
          resolve(response);
        })
      });
  };

  get = (route, options) => {
    return this.call(route, merge.recursive(options, {method: "GET"}));
  };

  post = (route, options) => {
    return this.call(route, merge.recursive(options, {method: "POST"}));
  };

  delete = (route, options) => {
    return this.call(route, merge.recursive(options, {method: "DELETE"}));
  };

  patch = (route, options) => {
    return this.call(route, merge.recursive(options, {method: "PATCH"}));
  };

  put = (route, options) => {
    return this.call(route, merge.recursive(options, {method: "PUT"}));
  };
}

export default Client;
