import * as React from 'react';
import {Table, TableCell, TableHeaderCell, TableWrapper} from "../Structure/Table";

const UserDetails = ({user}) =>
{
  return (
    <TableWrapper>
      <Table>
        <tbody>
        <tr>
          <TableHeaderCell>First name</TableHeaderCell>
          <TableCell>{user.firstName}</TableCell>
        </tr>
        <tr>
          <TableHeaderCell>Last name</TableHeaderCell>
          <TableCell>{user.lastName}</TableCell>
        </tr>
        <tr>
          <TableHeaderCell>Language</TableHeaderCell>
          <TableCell>{user.language}</TableCell>
        </tr>
        <tr>
          <TableHeaderCell>Channel</TableHeaderCell>
          <TableCell>{user.channel ? user.channel : <i>No channel set</i>}</TableCell>
        </tr>
        </tbody>
      </Table>
    </TableWrapper>
  )
};

export default UserDetails;