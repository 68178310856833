import * as React from "react";

export const TableWrapper = ({children}) => (
  <div className="overflow-x-auto bg-white rounded-lg shadow overflow-y-auto relative">
    {children}
  </div>
);

export const Table = ({children}) => (
  <table className="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
    {children}
  </table>
);

export const TableCell = ({children}) => (
  <td className="border-t border-gray-200 text-gray-700 px-6 py-3">{children}</td>
);

export const TableHeaderCell = ({children}) => (
  <th className="bg-gray-100 sticky top-0 border-b border-gray-200 px-6 py-4 text-gray-600 font-bold tracking-wider uppercase text-xs text-left ">
    {children}
  </th>
);