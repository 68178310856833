import getConfig from "../config";
import client from "../middlewares/client";
import {getAccessTokenAsPromise, refreshTokenIfNeeded} from "../utils/auth";

const config = getConfig();

const getAuthentication = () => {
  return getAccessTokenAsPromise().then(token => 'Bearer ' + token);
};

export const getClient = () => {
  return new client({headers: {'Content-Type': 'application/json'}}, refreshTokenIfNeeded, getAuthentication);
};

export const getToken = token => {
  const Client = new client({headers: {'Content-Type': 'application/json'}});

  return Client.get(`${config.api_url}/tokens/${token}`).then(result => {
    if (['4', '5'].includes(result.statusCode.toString().charAt(0))) {
      throw new Error('The token was invalid: something went wrong fetching the token.');
    }

    return result;
  });
};

export const refreshToken = refreshToken => {
  const Client = new client({headers: {'Content-Type': 'application/json'}});

  return Client.post(`${config.api_url}/oauth/access_token?grant_type=refresh_token&client_id=${getConfig().client_id}`, {
    body: JSON.stringify({
      refresh_token: refreshToken,
    })
  });
};

export const getMe = () => getClient().get(`${config.api_url}/api/users/me`);

export const deleteMe = () => getClient().delete(`${config.api_url}/api/users/me`);

export const getUsers = (page, pageSize, filters = []) => {
  let url = `${config.api_url}/api/users?page=${page}&page_size=${pageSize}`;

  filters.forEach(filter => {
    url = url + '&filters[]=' + filter;
  });

  return getClient().get(url);
};

export const getUser = userId => getClient().get(`${config.api_url}/api/users/${userId}`);

export const updateUser = (userId, user) => getClient().patch(`${config.api_url}/api/users/${userId}`, {
  body: JSON.stringify(user),
});

export const updateProperty = (userId, property, value) => getClient().put(`${config.api_url}/api/users/${userId}/${property}`, {
  body: value,
});

export const deleteUser = userId => getClient().delete(`${config.api_url}/api/users/${userId}`);

export const getServices = userId => getClient()
  .get(`${config.api_url}/api/users/${userId}/third-party-connections?page=0&page_size=100`, {headers: {'Content-Type': 'text/plain'}})
;

export const deleteService = serviceId => getClient()
  .delete(`${config.api_url}/api/users/me/third-party-connections/${serviceId}`)
;

export const getMetrics = () => getClient().get(`${config.api_url}/api/metrics`);