import i18next from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import getConfig from "../config";

i18next
  .use(Backend)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    nonExplicitWhitelist: true,
    debug: getConfig().debug,
    interpolation: {
      escapeValue: false
    },
    react: {
      wait: true,
    },
    backend: {
      loadPath: getConfig().translationsPrefix + '.{{lng}}.json'
    }
  });

export default i18next;