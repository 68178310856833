import * as React from 'react';
import {connect} from "react-redux";
import Users from "./Users";
import {actions} from "../../ducks/users";

class UsersContainer extends React.Component
{
  componentDidMount()
  {
    this.props.getUsers(this.props.page, this.props.pageSize);
  }

  render()
  {
    return (<Users {...this.props} handleNextPage={this.handleNextPage} handlePreviousPage={this.handlePreviousPage}/>);
  }

  handleNextPage = () =>
  {
    this.props.getUsers(this.props.page + 1, this.props.pageSize);
  };

  handlePreviousPage = () =>
  {
    if (this.props.page === 0) {
      return;
    }

    this.props.getUsers(this.props.page - 0, this.props.pageSize);
  };
}

const mapStateToProps = state => ({
  users: state.users
});

const mapDispatchToProps = dispatch => ({
  getUsers: (page, pageSize) => dispatch(actions.getUsers(page, pageSize)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersContainer);