import * as React from 'react';
import Template from "../Structure/Template/Template";
import H2 from "../Structure/Tailwind/H2";
import Loader from "../Structure/Loader/Loader";
import {REQUEST_STATES} from "../../consts/request-states";

const Dashboard = props => {
    const {metrics, requestState} = props.metrics;
    const isLoading = [REQUEST_STATES.init, REQUEST_STATES.loading].includes(requestState);

    return (
        <Template>
            <H2>Dashboard</H2>

            <div className="rounded w-1/3 shadow-sm bg-white p-4">
                Welcome to the Gameplay dashboard. <br /><br />

                {isLoading && <Loader/>}

                <table>
                    <thead>
                        <tr colspan="2">
                            <th>
                                Total users: {metrics.totalUsers}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {!isLoading && metrics.usersPerClient.map((client, index) => (
                            <tr key={index}>
                                <td>{client.name}</td>
                                <td>{client.count}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </Template>
    )
};

export default Dashboard;