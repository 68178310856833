import * as React from 'react';
import Logo from "../Logo/Logo";
import Menu from "../Menu/Menu";
import Button from "../Tailwind/Button";
import {logout} from "../../../utils/auth";
import {withTranslation} from "react-i18next";

const Template = ({t, children}) => {
  return (
    <div className="h-screen w-screen bg-white flex">
      <div className="sm:w-1/3 md:w-1/4 lg:w-1/5 h-full shadow-2xl p-4 flex flex-col justify-between">
        <Logo />
        <Menu/>
        <div>
          <Button onClick={() => logout()}>{t('Logout')}</Button>
        </div>
      </div>

      <div className="sm:w-2/3 md:w-3/4 lg:w-4/5 h-full overflow-y-scroll bg-gray-100 p-4">
        {children}
      </div>
    </div>
  );
};

export default withTranslation()(Template);