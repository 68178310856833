import * as React from "react";

const CenteredContainer = props =>
{
  const constraints = {minHeight: '480px', height: '100%', maxHeight: '60vh', minWidth: '600px', maxWidth: '60vw', width: '100%'};

  const filterKeys = ['children', 'withoutFlex'];

  const propsWithoutChildren = Object.keys(props).filter(key => !filterKeys.includes(key)).reduce((object, key) => {
    object[key] = props[key];
    return object
  }, {});

  if (props.withoutFlex) {
    return (
      <div className="flex justify-center items-center h-screen" {...propsWithoutChildren}>
        <div
          className="container-md border-2 rounded w-7/12 flex bg-white shadow-2xl"
          style={constraints}
        >
          {props.children}
        </div>
      </div>
    )
  }

  return (
    <div className="flex justify-center items-center h-screen" {...propsWithoutChildren}>
      <div
        className="container-md border-2 rounded w-7/12 flex justify-between items-stretch bg-white shadow-2xl"
        style={constraints}
      >
        {props.children}
      </div>
    </div>
  );
};

export default CenteredContainer;