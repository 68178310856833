import * as React from 'react';

import EnsureLoggedInContainer from "../components/Structure/Auth/EnsureLoggedInContainer/EnsureLoggedInContainer";
import UsersContainer from "../components/Users/UsersContainer";
import Template from "../components/Structure/Template/Template";

const UsersScene = props =>
{
  const params = new URLSearchParams(props.location.search);

  return (
    <EnsureLoggedInContainer>
      <Template>
        <UsersContainer
          page={params.get('page') || 0}
          pageSize={params.get('page_size') || 100}
        />
      </Template>
    </EnsureLoggedInContainer>
  );
};

export default UsersScene;