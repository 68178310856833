import * as React from 'react';
import Button, {DangerButton} from "../Structure/Tailwind/Button";
import RandomString from 'random-string';
import {actions} from "../../ducks/user";
import {connect} from "react-redux";
import {REQUEST_STATES} from "../../consts/request-states";

class UserActions extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state = {
      openForm: null,
      password: RandomString({length: 16}),
      username: props.user.username,
      email: props.user.email,
    }
  }

  componentDidUpdate(prevProps)
  {
    const sameDeleteState = prevProps.deletionRequestState === this.props.deletionRequestState;

    if (sameDeleteState) {
      return;
    }

    if (this.props.deletionRequestState === REQUEST_STATES.success) {
      this.props.getUser(this.props.user.id);
    }
  }

  handleUpdateProperty = property => {
    if (property === 'isBanned') {
      this.props.updateProperty(this.props.user.id, 'isBanned', !this.props.user.isBanned);
      this.setState({openForm: null});
      return;
    }

    this.props.updateProperty(this.props.user.id, property, this.state[property]);
  };

  handleDelete = () => {
    this.props.deleteUser(this.props.user.id);
  };

  renderUsernameForm = () => {
    return (
      <div className="flex items-baseline my-4">
        <input
          type="text"
          className="rounded p-2 border w-64 mr-4"
          onChange={event => this.setState({username: event.target.value})}
          value={this.state.username}
        />

        <Button onClick={() => this.handleUpdateProperty('username')}>
          {this.props.requestState === REQUEST_STATES.loading ? 'Loading': 'Save'}
        </Button>
        <Button onClick={this.resetView}>Cancel</Button>
      </div>
    )
  };

  renderEmailForm = () => {
    return (
      <div className="flex items-baseline my-4">
        <input
          type="email"
          className="rounded p-2 border w-64 mr-4"
          id="email"
          onChange={event => this.setState({email: event.target.value})}
          value={this.state.email}
        />

        <Button onClick={() => this.handleUpdateProperty('email')}>
          {this.props.requestState === REQUEST_STATES.loading ? 'Loading': 'Save'}
        </Button>
        <Button onClick={this.resetView}>Cancel</Button>
      </div>
    )
  };

  resetView = () => this.setState({openForm: null});

  renderPasswordForm = () => {
    return (
      <div className="flex items-baseline my-4">
        <div className="flex flex-col">
          <input
            type="text"
            className="rounded p-2 border w-64 mr-4"
            onChange={event => this.setState({password: event.target.value})}
            value={this.state.password}
          />
          <i>This is a suggested password</i>
        </div>

        <Button onClick={() => this.handleUpdateProperty('password')}>
          {this.props.requestState === REQUEST_STATES.loading ? 'Loading': 'Save'}
        </Button>

        <Button onClick={this.resetView}>Cancel</Button>
      </div>
    )
  };

  renderDeleteForm = () => {
    return (
      <div className="flex justify-between my-4">
        <div className="bg-white p-2 rounded border">
          Are you sure that you want to delete this user?
        </div>

        <div className="flex">
          <DangerButton onClick={this.handleDelete}>
            {this.props.deletionRequestState === REQUEST_STATES.loading ? 'Loading': 'Confirm'}
          </DangerButton>
          <Button onClick={this.resetView}>Cancel</Button>
        </div>
      </div>
    )
  };

  renderBanForm = () => {
    return (
      <div className="flex justify-between my-4">
        <div className="bg-white p-2 rounded border">
          Are you sure that you want to {this.props.user.isBanned ? 'unban' : 'ban'} this user?
        </div>

        <div className="flex">
          <DangerButton onClick={() => this.handleUpdateProperty('isBanned')}>
            {this.props.requestState === REQUEST_STATES.loading ? 'Loading': 'Confirm'}
          </DangerButton>
          <Button onClick={this.resetView}>Cancel</Button>
        </div>
      </div>
    );
  };

  renderForm = () => {
    let form = null;

    switch (this.state.openForm) {
      case 'username':
        form = this.renderUsernameForm();
        break;
      case 'email':
        form = this.renderEmailForm();
        break;
      case 'password':
        form = this.renderPasswordForm();
        break;
      case 'delete':
        form = this.renderDeleteForm();
        break;
      case 'ban':
        form = this.renderBanForm();
        break;
    }

    if ((this.props.error || this.props.deletionError)) {
      return (
        <div className="mt-4">
          <div className="rounded bg-red-200 border-red-400 text-red-700 p-4">
            {this.props.error}
            {this.props.deletionError}
          </div>
          {form}
        </div>
      )
    }

    return form;
  };

  render()
  {
    if (this.state.openForm !== null) {
      return this.renderForm();
    }

    return (
      <div className="flex justify-between my-4">
        <div className="flex">
          <Button onClick={() => this.setState({openForm: 'username'})}>Change username</Button>
          <Button onClick={() => this.setState({openForm: 'email'})}>Change email</Button>
          <Button onClick={() => this.setState({openForm: 'password'})}>Change password</Button>
        </div>

        <div>
          <DangerButton onClick={() => this.setState({openForm: 'ban'})}>{this.props.user.isBanned ? 'Unban' : 'Ban'}</DangerButton>
          <DangerButton onClick={() => this.setState({openForm: 'delete'})}>Delete</DangerButton>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  requestState: state.user.updateRequestState,
  deletionRequestState: state.user.deletionRequestState,
  error: state.user.updateError,
  deletionError: state.user.deletionError,
});

const mapDispatchToProps = dispatch => ({
  updateProperty: (userId, property, value) => dispatch(actions.updateProperty(userId, property, value)),
  getUser: userId => dispatch(actions.getUser(userId)),
  deleteUser: userId => dispatch(actions.deleteUser(userId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserActions);