import * as React from 'react';
import CenteredContainer from "../Structure/CenteredContainer/CenteredContainer";
import {logout, startAuth} from "../../utils/auth";
import {withTranslation} from "react-i18next";
import H2 from "../Structure/Tailwind/H2";
import Button from "../Structure/Tailwind/Button";
import {withRouter} from "react-router";
import CenteredContainerLoader from "../Structure/Loader/CenteredContainerLoader";
import {REQUEST_STATES} from "../../consts/request-states";
import Logo from "../Structure/Logo/Logo";

const Home = props => {
  const translate = props.t;

  if (props.userRequest === REQUEST_STATES.failed) {
    return (
      <CenteredContainer withoutFlex>
        <div className="flex flex-col items-center justify-center w-full">
          <p className="mb-2 w-8/12">{translate('Something went wrong fetching your user. This happens becomes something unexpected has happened.')}</p>
          <p className="mb-4 w-8/12">{translate('Please contact support to get access to this platform or try logging out and try again.')}</p>

          <div className="w-8/12">
            <Button onClick={() => logout()}>{translate('Logout')}</Button>
          </div>
        </div>
      </CenteredContainer>
    )
  }

  if (props.auth.isAuthenticating || (props.auth.authenticated && props.user === null)) {
    return (
      <CenteredContainerLoader />
    )
  }

  return (
    <CenteredContainer>
      <div className="w-5/12 border-r p-6">
        <Logo />
      </div>
      <div className="w-7/12 p-6">
        <div className="flex flex-col justify-between h-full">
          <H2 className="mb-6">{translate('Howdy, stranger')}</H2>

          <div>
            <Button onClick={startAuth}>{translate('Login')}</Button>
          </div>
        </div>
      </div>
    </CenteredContainer>
  )
};

export default withTranslation()(withRouter(Home));