import * as React from 'react';
import {Table, TableCell, TableHeaderCell, TableWrapper} from "../Structure/Table";
import moment from 'moment';

const UserDates = ({user}) =>
{
  return (
    <div className="mt-4">
      <TableWrapper>
        <Table>
          <tbody>
          <tr>
            <TableHeaderCell>Created at/Registration</TableHeaderCell>
            <TableCell>{moment(user.createdAt).fromNow()}</TableCell>
          </tr>
          <tr>
            <TableHeaderCell>Accepted TOS at</TableHeaderCell>
            <TableCell>{user.tosApprovalDate ? moment(user.tosApprovalDate).fromNow() : <i>Not yet approved</i>}</TableCell>
          </tr>
          <tr>
            <TableHeaderCell>Accepted Privacy Policy at</TableHeaderCell>
            <TableCell>{user.privacyPolicyApprovalDate ? moment(user.privacyPolicyApprovalDate).fromNow() : <i>Not yet approved</i>}</TableCell>
          </tr>
          <tr>
            <TableHeaderCell>Deleted at</TableHeaderCell>
            <TableCell>{user.deletedAt ? moment(user.deletedAt).fromNow() : <i>Not deleted</i>}</TableCell>
          </tr>
          </tbody>
        </Table>
      </TableWrapper>
    </div>
  )
};

export default UserDates;