import produce from "immer";
import {createAsyncAction} from "../middlewares/redux-async";
import {REQUEST_STATES} from "../consts/request-states";
import {getMetrics} from "../api/gameplay";

export const types = {
    GET_METRICS_LOADING: 'metrics::metrics::loading',
    GET_METRICS_SUCCESS: 'metrics::metrics::success',
    GET_METRICS_FAILURE: 'metrics::metrics::failure',
};

const initialState = {
    requestState: REQUEST_STATES.init,
    metrics: {}
};

export default function metrics(previousState = initialState, action) {
    return produce(previousState, newState => {
        switch (action.type) {
            case types.GET_METRICS_LOADING:
                newState.requestState = REQUEST_STATES.loading;

                return newState;

            case types.GET_METRICS_SUCCESS:
                newState.requestState = REQUEST_STATES.success;
                newState.metrics = action.payload;

                return newState;

            case types.GET_METRICS_FAILURE:
                newState.requestState = REQUEST_STATES.failed;
                newState.metrics = {};

                return newState;

            default:
                return newState;
        }
    });
}

export const actions = {
    getMetrics: () => createAsyncAction({
        asyncRequest: getMetrics,
        requestType: types.GET_METRICS_LOADING,
        successType: types.GET_METRICS_SUCCESS,
        failureType: types.GET_METRICS_FAILURE,
    }),
};
