import * as React from 'react';

import './Loader.css';

const Loader = props => (
  <div className="lds-ripple">
    <div />
    <div />
  </div>
);

export default Loader;