import { types } from '../ducks/ui.js';

export function createAsyncAction({asyncRequest, requestType, requestPayload, successType, failureType, fireGeneralFailure = true}) {
  return (dispatch) => {
    dispatch({ type: requestType, payload: requestPayload });


    return asyncRequest()
      .then((data) => {
        if ([401, 403].includes(data.statusCode)) {
          return;
        }

        const isPotentialErrorStatusCode = [400, 402, 404, 500, 501, 502, 503, 504].includes(data.statusCode);

        if (isPotentialErrorStatusCode && fireGeneralFailure) {
          dispatch({ type: types.GENERAL_API_FAILURE, payload: data});
        }

        if (isPotentialErrorStatusCode) {
          dispatch({ type: failureType, payload: data.json.message, requestPayload });
          return;
        }

        dispatch({ type: successType, payload: data.json, requestPayload });
        return data;
      })
      .catch(error => {
        dispatch({ type: failureType, payload: error, requestPayload });
        throw error;
      });
  };
}
