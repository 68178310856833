function getConfig()
{
  return {
    "translationsPrefix": "https://login.gameplay.be/build/translations/admin",
    "debug": false,
    "oauthUrl": 'https://login.gameplay.be/oauth/authorize',
    "scopes": ['basic_information', 'admin'],
    "client_id": "a6fb090d-4292-4dac-b8f5-27a03ad4af35",
    "api_url": 'https://login.gameplay.be'
  }
}

export default getConfig;
