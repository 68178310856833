import React, { Component } from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import CenteredContainerLoader from "../../Loader/CenteredContainerLoader";

class EnsureLoggedInContainer extends Component
{
  constructor(props)
  {
    super(props);

    this.state = {
      interval: null,
    }
  }

  componentDidMount = () =>
  {
    if (!this.props.isAuthenticating && !this.props.authenticated) {
      this.props.history.push('/');
    }
  };

  componentDidUpdate = (props) =>
  {
    if (!this.props.isAuthenticating && !this.props.authenticated) {
      this.props.history.push('/');
    }
  };

  render()
  {
    if (this.props.isAuthenticating) {
      return (<CenteredContainerLoader />);
    }

    return (
      <React.Fragment>
        {this.props.children}
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => ({
  ...state.auth,
});

/** Not using mapDispatchToProps, since we need dispatch inside of the component. **/
export default connect(mapStateToProps)(withRouter(EnsureLoggedInContainer));
