import * as React from 'react';
import {connect} from "react-redux";

import Home from "./Home";
import {actions} from "../../ducks/auth";
import {getUserId} from "../../utils/auth";
import {withRouter} from "react-router";

class HomeContainer extends React.Component
{
  componentDidMount()
  {
    if (!this.props.auth.isAuthenticating && this.props.auth.authenticated) {
      this.props.getUser(getUserId());
      this.props.history.push('/dashboard');
    }
  }

  componentDidUpdate(prevProps) {
    const isEqual = prevProps.auth.isAuthenticating === this.props.auth.isAuthenticating && this.props.auth.authenticated === prevProps.auth.authenticated;

    if (isEqual) {
      return;
    }

    if (!this.props.auth.isAuthenticating && this.props.auth.authenticated) {
      this.props.getUser(getUserId());
      this.props.history.push('/dashboard');
    }
  }

  render()
  {
    return (
      <Home {...this.props} />
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.auth.user.user,
  userRequest: state.auth.user.requestState
});

const mapDispatchToProps = dispatch => ({
  getUser: id => dispatch(actions.getUser(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeContainer));