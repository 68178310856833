import * as React from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router";

const menu = [
  {key: 'dashboard', label: 'Dashboard', to: '/dashboard'},
  {key: 'users', label: 'Users', to: '/users'},
];

const Menu = ({t, history, active}) =>
{
  return (
    <div>
      <ul className="mt-10">
        {menu.map(menuItem => (
          <li
            className={`cursor-pointer font-medium ${menuItem.key === active ? 'text-red-700' : ''}`}
            key={menuItem.key}
            onClick={() => history.push(menuItem.to)}
          >
            {t(menuItem.label)}
          </li>
        ))}
      </ul>
    </div>
  )
};

export default withTranslation()(withRouter(Menu));