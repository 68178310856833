import {applyMiddleware, createStore, compose, combineReducers} from 'redux';
import thunkMiddleware from 'redux-thunk';

import auth from './ducks/auth';
import users from "./ducks/users";
import user from "./ducks/user";
import metrics from "./ducks/metrics";

export function configureStore(history, preloadedState) {
  const rootReducer = combineReducers({
    auth,
    users,
    user,
    metrics,
  });

  const middlewares = [thunkMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const hasExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  const enhancers = [middlewareEnhancer];

  if (hasExtension) {
    enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__());
  }

  const composedEnhancers = compose(...enhancers);

  return createStore(rootReducer, preloadedState, composedEnhancers);
}
