import produce from "immer";
import {createAsyncAction} from "../middlewares/redux-async";
import {REQUEST_STATES} from "../consts/request-states";
import {getUser, updateProperty, deleteUser} from "../api/gameplay";

export const types = {
  GET_USER_LOADING: 'user::get::loading',
  GET_USER_SUCCESS: 'user::get::success',
  GET_USER_FAILURE: 'user::get::failure',
  UPDATE_PROPERTY_LOADING: 'user::property-update::loading',
  UPDATE_PROPERTY_SUCCESS: 'user::property-update::success',
  UPDATE_PROPERTY_FAILURE: 'user::property-update::failure',
  DELETE_USER_LOADING: 'user::delete::loading',
  DELETE_USER_SUCCESS: 'user::delete::success',
  DELETE_USER_FAILURE: 'user::delete::failure',
};

const initialState = {
  requestState: REQUEST_STATES.init,
  updateRequestState: REQUEST_STATES.init,
  deleteRequestState: REQUEST_STATES.init,
  user: null,
  error: null,
  updateError: null,
  deleteError: null,
};

export default function user(previousState = initialState, action) {
  return produce(previousState, newState => {
    switch (action.type) {
      case types.GET_USER_LOADING:
        newState.requestState = REQUEST_STATES.loading;
        newState.user = null;
        newState.error = null;
        return newState;

      case types.GET_USER_SUCCESS:
        newState.requestState = REQUEST_STATES.success;
        newState.user = action.payload;
        newState.error = null;
        return newState;

      case types.GET_USER_FAILURE:
        newState.requestState = REQUEST_STATES.failed;
        newState.error = action.payload;
        return newState;

      case types.UPDATE_PROPERTY_LOADING:
        newState.updateRequestState = REQUEST_STATES.loading;
        newState.updateError = null;
        return newState;

      case types.UPDATE_PROPERTY_SUCCESS:
        newState.updateRequestState = REQUEST_STATES.success;
        newState.updateError = null;
        newState.user = action.payload;
        return newState;

      case types.UPDATE_PROPERTY_FAILURE:
        newState.updateRequestState = REQUEST_STATES.failed;
        newState.updateError = action.payload;
        return newState;

      case types.DELETE_USER_LOADING:
        newState.deleteRequestState = REQUEST_STATES.loading;
        newState.deleteError = null;
        return newState;

      case types.DELETE_USER_SUCCESS:
        newState.deleteRequestState = REQUEST_STATES.success;
        newState.deleteError = null;
        return newState;

      case types.DELETE_USER_FAILURE:
        newState.deleteRequestState = REQUEST_STATES.failed;
        newState.deleteError = action.payload;
        return newState;

      default:
        return newState;
    }
  });
}

export const actions = {
  getUser: userId => createAsyncAction({
    asyncRequest: getUser.bind(null, userId),
    requestType: types.GET_USER_LOADING,
    successType: types.GET_USER_SUCCESS,
    failureType: types.GET_USER_FAILURE,
  }),
  updateProperty: (userId, property, value) => createAsyncAction({
    asyncRequest: updateProperty.bind(null, userId, property, value),
    requestType: types.UPDATE_PROPERTY_LOADING,
    successType: types.UPDATE_PROPERTY_SUCCESS,
    failureType: types.UPDATE_PROPERTY_FAILURE,
  }),
  deleteUser: userId => createAsyncAction({
    asyncRequest: deleteUser.bind(null, userId),
    requestType: types.DELETE_USER_LOADING,
    successType: types.DELETE_USER_SUCCESS,
    failureType: types.DELETE_USER_FAILURE,
  }),
};
