import * as React from 'react';

import EnsureLoggedInContainer from "../components/Structure/Auth/EnsureLoggedInContainer/EnsureLoggedInContainer";
import DashboardContainer from "../components/Dashboard/DashboardContainer";

const DashboardScene = props =>
{
  return (
    <EnsureLoggedInContainer><DashboardContainer /></EnsureLoggedInContainer>
  );
};

export default DashboardScene;