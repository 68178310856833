import produce from "immer";
import {createAsyncAction} from "../middlewares/redux-async";
import {REQUEST_STATES} from "../consts/request-states";
import {deleteMe, getMe, updateUser} from "../api/gameplay";

export const types = {
  AUTHENTICATE: 'auth::authenticate',
  IS_AUTHENTICATING: 'auth::is_authenticating',
  IS_NO_LONGER_AUTHENTICATING: 'auth::is_no_longer_authenticating',
  GET_USER_LOADING: 'auth::user::loading',
  GET_USER_FAILURE: 'auth::user::failed',
  GET_USER_SUCCESS: 'auth::user::success',
  UPDATE_USER_LOADING: 'auth::user::update::loading',
  UPDATE_USER_FAILURE: 'auth::user::update::failed',
  UPDATE_USER_SUCCESS: 'auth::user::update::success',
  DELETE_USER_LOADING: 'auth::user::delete::loading',
  DELETE_USER_SUCCESS: 'auth::user::delete::success',
  DELETE_USER_FAILURE: 'auth::user::delete::failure',
  LOGOUT: 'auth::logout',
};

const initialState = {
  isAuthenticating: false,
  authenticated: false,
  token: null,
  userId: null,
  user: {
    requestState: REQUEST_STATES.init,
    updateState: REQUEST_STATES.init,
    deletionState: REQUEST_STATES.init,
    updateError: null,
    user: null,
  },
};

export default function auth(previousState = initialState, action) {
  return produce(previousState, newState => {
    switch (action.type) {
      case types.IS_AUTHENTICATING:
        newState.isAuthenticating = true;
        return newState;

      case types.AUTHENTICATE:
        newState.authenticated = true;
        newState.isAuthenticating = false;
        newState.token = action.payload;

        return newState;

      case types.IS_NO_LONGER_AUTHENTICATING:
        newState.authenticated = false;
        newState.isAuthenticating = false;

        return newState;

      case types.GET_USER_LOADING:
        newState.user.requestState = REQUEST_STATES.loading;
        newState.user.user = null;

        return newState;

      case types.GET_USER_SUCCESS:
        newState.user.requestState = REQUEST_STATES.success;
        newState.user.user = action.payload;
        newState.isAuthenticating = false;

        return newState;

      case types.LOGOUT:
        return initialState;

      case types.UPDATE_USER_LOADING:
        newState.user.updateState = REQUEST_STATES.loading;
        newState.user.updateError = null;

        return newState;

      case types.UPDATE_USER_SUCCESS:
        newState.user.updateState = REQUEST_STATES.success;
        newState.user.user = action.payload;

        return newState;

      case types.UPDATE_USER_FAILURE:
        newState.user.updateState = REQUEST_STATES.failed;
        newState.user.updateError = action.payload;

        return newState;

      case types.DELETE_USER_LOADING:
        newState.user.deletionState = REQUEST_STATES.loading;
        return newState;

      case types.DELETE_USER_SUCCESS:
        newState.user.deletionState = REQUEST_STATES.success;
        return newState;

      case types.DELETE_USER_FAILURE:
        newState.user.deletionState = REQUEST_STATES.failed;
        return newState;

      case types.GET_USER_FAILURE:
        newState.user.requestState = REQUEST_STATES.failed;
        newState.user.user = null;

        return newState;

      default:
        return newState;
    }
  });
}

export const actions = {
  authenticate: (token, userId) => ({type: types.AUTHENTICATE, payload: {token, userId}}),
  isAuthenticating: () => ({type: types.IS_AUTHENTICATING}),
  isNoLongerAuthenticating: () => ({type: types.IS_NO_LONGER_AUTHENTICATING}),
  getUser: () => createAsyncAction({
    asyncRequest: getMe,
    requestType: types.GET_USER_LOADING,
    successType: types.GET_USER_SUCCESS,
    failureType: types.GET_USER_FAILURE,
  }),
  updateUser: (id, user) => createAsyncAction({
    asyncRequest: updateUser.bind(null, id, user),
    requestType: types.UPDATE_USER_LOADING,
    successType: types.UPDATE_USER_SUCCESS,
    failureType: types.UPDATE_USER_FAILURE,
  }),
  deleteMe: () => createAsyncAction({
    asyncRequest: deleteMe,
    requestType: types.DELETE_USER_LOADING,
    successType: types.DELETE_USER_SUCCESS,
    failureType: types.DELETE_USER_FAILURE,
  }),
  logout: () => ({type: types.LOGOUT}),
};
