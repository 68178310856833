import * as React from 'react';

const UserRoles = ({user}) => {
  return (
    <div className="bg-white p-6 rounded shadow-md">
      <strong>Roles</strong>

      <hr className="my-4"/>

      <ul>
        {user.roles.map(role => <li key={role}>{role}</li>)}
      </ul>
    </div>
  );
};

export default UserRoles;