import * as React from 'react';
import {withRouter} from "react-router";

class UserNavigator extends React.Component
{
  constructor(props) {
    super(props);

    this.state = {
      userId: '',
    }
  }

  handleOnChange = event => {
    this.setState({userId: event.target.value});
  };

  handleOnEnter = event => {
    if (event.keyCode !== 13) {
      return;
    }

    event.preventDefault();
    event.stopPropagation();
    this.props.history.push(`/users/${this.state.userId}`)
  };

  render()
  {
    return (
      <input
        type="search"
        className="sm:w-1/2 md:w-1/4 py-2 px-4 rounded-lg shadow focus:outline-none focus:shadow-outline text-gray-600 font-medium"
        placeholder="Find user via username/email/id"
        onChange={this.handleOnChange}
        onKeyUp={this.handleOnEnter}
      />
    );
  }
}

export default withRouter(UserNavigator);