import * as React from 'react';
import {Table, TableCell, TableHeaderCell, TableWrapper} from "../Structure/Table";

const UserState = ({user}) =>
{
  return (
    <TableWrapper>
      <Table>
        <tbody>
        <tr>
          <TableHeaderCell>Active</TableHeaderCell>
          <TableHeaderCell>Banned</TableHeaderCell>
          <TableHeaderCell>Wants deletion</TableHeaderCell>
          <TableHeaderCell>Wants marketing</TableHeaderCell>
        </tr>
        <tr>
          <TableCell>{user.isActive ? 'Yes' : 'No'}</TableCell>
          <TableCell>{user.isBanned ? 'Yes' : 'No'}</TableCell>
          <TableCell>{user.isRequestingToBeDeleted ? 'Yes' : 'No'}</TableCell>
          <TableCell>{user.hasAllowedMarketing ? 'Yes' : 'No'}</TableCell>
        </tr>
        </tbody>
      </Table>
    </TableWrapper>
  )
};

export default UserState;
