import React from 'react';
import {withTranslation} from "react-i18next";

import {exchangeCode} from "../../utils/auth";
import { actions } from '../../ducks/auth';

import CenteredContainer from "../Structure/CenteredContainer/CenteredContainer";
import {connect} from "react-redux";
import history from "../../history";


class AuthContainer extends React.Component
{
  componentDidMount()
  {
    const params = new URL(window.location).searchParams;

    exchangeCode(params.get('code')).then(userId => {
      this.props.getUser(userId);

      history.push('/');
    });
  }

  render()
  {
    const translate = this.props.t;

    return (
      <CenteredContainer withoutFlex>
        <div className="flex items-center justify-center w-full">
          {translate('Authenticating')}
        </div>
      </CenteredContainer>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getUser: id => dispatch(actions.getUser(id)),
});

export default withTranslation()(connect(() => ({}), mapDispatchToProps)(AuthContainer));