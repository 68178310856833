import * as React from 'react';
import {connect} from "react-redux";
import {actions} from "../../ducks/users";
import ToggleableButton from "../Structure/Tailwind/ToggleableButton";

const toggleFilter = (filters, filter) => {
  if (!filters.includes(filter)) {
    return [...filters, filter];
  }

  return filters.filter(existingFilter => existingFilter !== filter);
};

class UsersFilters extends React.Component
{
  handleClickButton = filter => {
    this.props.getUsers(
      0,
      this.props.pageSize,
      toggleFilter(this.props.filters, filter)
    );
  };

  render()
  {
    const {filters} = this.props;

    return (
      <div className="flex justify-start items-center">
        <ToggleableButton
          isToggled={filters.includes('to-be-removed')}
          onClick={this.handleClickButton.bind(null, 'to-be-removed')}
        >
          To be deleted
        </ToggleableButton>

        <ToggleableButton
          isToggled={filters.includes('all')}
          onClick={this.handleClickButton.bind(null, 'all')}
        >
          Include inactive
        </ToggleableButton>
      </div>
    );
  }
};

const mapStateToProps = state => ({
  filters: state.users.filters,
  page: state.users.page,
  pageSize: state.users.pageSize
});

const mapDispatchToProps = dispatch => ({
  getUsers: (page, pageSize, filters) => dispatch(actions.getUsers(page, pageSize, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersFilters);