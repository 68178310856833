import * as React from 'react';
import Dashboard from "./Dashboard";
import {actions} from "../../ducks/metrics";
import {connect} from "react-redux";

class DashboardContainer extends React.Component
{
  componentDidMount()
  {
    this.props.getMetrics();
  }

  render()
  {
    return (<Dashboard {...this.props} />)
  }
}

const mapStateToProps = state => ({
  metrics: state.metrics
});

const mapDispatchToProps = dispatch => ({
  getMetrics: () => dispatch(actions.getMetrics()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardContainer);