import RandomString from 'random-string';
import getConfig from '../config';
import toQueryString from './to-query-string';
import { store} from '../index';
import {getToken, refreshToken as apiRefreshToken } from "../api/gameplay";
import { types } from '../ducks/auth';
import camelcaseKeys from 'camelcase-keys';

export function startAuth() {
  const challenge = RandomString({length: 128});

  sessionStorage.setItem('oauth-challenge', challenge);

  const config = getConfig();
  
  const queryParams = {
    'scope': config.scopes,
    'redirect_uri': window.location.origin + '/auth',
    'challenge': challenge,
    'client_id': config.client_id,
  };

  window.location = config.oauthUrl + '?' + toQueryString(queryParams);
}

export function exchangeCode(code) {
  const config = getConfig();

  const verifier = sessionStorage.getItem('oauth-challenge');

  const queryParams = {
    'grant_type': 'authorization_code',
    'code': code,
    'verifier': verifier,
    'client_id': config.client_id,
  };

  return fetch(config.api_url + '/oauth/access_token?' + toQueryString(queryParams), {method: 'POST'})
    .then(response => response.json())
    .then(token => {
      persistTokenToLocalStorage(token);

      store.dispatch({
        type: 'auth::authenticate',
        payload: {token: token.access_token, userId: token.user_id}
      });

      return token.user_id
    });
}

export function loadAuthFromLocalStorage () {
  const token = localStorage.getItem('accessToken');

  if (token === undefined || token === null) {
    return;
  }

  store.dispatch({type: types.IS_AUTHENTICATING});

  getToken(token)
    .then(response => {
      store.dispatch({
        type: types.AUTHENTICATE,
        payload: {token: localStorage.getItem('accessToken'), userId: localStorage.getItem('userId')}
      });
    })
    .catch(reason => {
      apiRefreshToken(localStorage.getItem('refreshToken'))
        .then(response => {
          persistTokenToLocalStorage(response.json);
          store.dispatch({
            type: types.AUTHENTICATE,
            payload: {token: localStorage.getItem('accessToken'), userId: localStorage.getItem('userId')}
          });
        })
        .catch(error => {
          store.dispatch({type: types.IS_NO_LONGER_AUTHENTICATING});
          deleteTokenFromLocalStorage();
        })
    });
}

export function logout () {
  deleteTokenFromLocalStorage();
  window.location = window.location.origin;
}

export function appendAccessTokenToUrl (url) {
  url = new URL(url);
  const params = new URLSearchParams(url.search.slice(1));

  params.append('access_token', getAccessToken());

  url.search = params.toString();

  return url.toString();
}

export function refreshTokenIfNeeded () {
  const refreshToken = localStorage.getItem('refreshToken');
  const timeLeftToExpire = localStorage.getItem('accessTokenValidUntil') - Math.floor(new Date().valueOf() / 1000);
  const timeLeftToExpireOnRefreshToken = localStorage.getItem('refreshTokenValidUntil') - Math.floor(new Date().valueOf() / 1000);

  if (timeLeftToExpire <= 300 && timeLeftToExpireOnRefreshToken <= 5) {
    console.error('Can\'t refresh since the token is expired');
    logout();
    return;
  }

  if (timeLeftToExpire <= 300 && refreshToken === undefined) {
    console.error('Can\'t refresh since the token is undefined');
    logout();
    return;
  }

  if (timeLeftToExpire > 300) {
    return;
  }

  return apiRefreshToken(refreshToken)
    .then(response => {
      if (response.statusCode !== 200) {
        console.error('Something went wrong with refreshing the user, logging out');
        logout();
        return;
      }

      persistTokenToLocalStorage(response.json);
    })
  ;
}

export function getUserId() {
  return localStorage.getItem('userId');
}

export function getAccessToken() {
  return localStorage.getItem('accessToken');
}

export function getAccessTokenAsPromise() {
  return new Promise((resolve, reject) => {
    setTimeout(() => resolve(getAccessToken()), 100)
  });
}

export function getRefreshToken() {
  return localStorage.getItem('refreshToken');
}

const deleteTokenFromLocalStorage = () => {
  const keys = ['accessToken', 'accessTokenValidUntil', 'refreshToken', 'refreshTokenValidUntil', 'userId', 'scopes'];

  keys.forEach(key => localStorage.removeItem(key));
};

export const persistTokenToLocalStorage = token => {
  token = camelcaseKeys(token, {deep: true});

  if (token.accessToken === undefined) {
    console.error('Tried to persist an undefined token');
    return;
  }

  localStorage.setItem('accessToken', token.accessToken);
  localStorage.setItem('accessTokenValidUntil', token.exp);
  localStorage.setItem('refreshToken', token.refreshToken);
  localStorage.setItem('refreshTokenValidUntil', token.expRefresh);
  localStorage.setItem('userId', token.userId);
  localStorage.setItem('scopes', token.scopes);
};