import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import './utils/translate';

import { Router } from "react-router-dom";
import {Route, Switch} from 'react-router'
import history from "./history";
import { Provider } from 'react-redux'
import {configureStore} from './store';
import AuthContainer from "./components/AuthContainer/AuthContainer";

import './assets/main.css';
import {loadAuthFromLocalStorage} from "./utils/auth";

import HomeScene from "./scenes/HomeScene";
import DashboardScene from "./scenes/DashboardScene";
import UsersScene from "./scenes/UsersScene";
import UserScene from "./scenes/UserScene";

export const store = configureStore(history);

loadAuthFromLocalStorage();

const App = props => (
  <div>
    <Provider store={store}>
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={HomeScene} />
            <Route path="/auth" component={AuthContainer} />
            <Route path="/dashboard" component={DashboardScene} />
            <Route exact path="/users" component={UsersScene} />
            <Route exact path="/users/:id" component={UserScene} />
          </Switch>
        </Router>
    </Provider>
  </div>
);

ReactDOM.render(
  <App />,
  document.getElementById('root')
);
