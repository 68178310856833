import * as React from 'react';

const ButtonClasses = [
  "rounded-md",
  "bg-blue-100",
  "hover:bg-blue-200",
  "text-blue-700",
  "border",
  "border-blue-300",
  "px-3",
  "py-1",
  "mb-2",
  "mr-1",
];

const DisabledClasses = [
  "opacity-25",
  "cursor-not-allowed",
];

const DangerClasses = [
  "rounded-md",
  "bg-red-100",
  "text-red-900",
  "hover:bg-red-200",
  "border",
  "border-red-300",
  "px-3",
  "py-1",
  "mb-2",
  "mr-1",
];

const getButtonClasses = (type = 'default', disabled, additionalClasses) => {
  let classes = [];

  switch (type) {
    case 'danger':
      classes = DangerClasses;
      break;
    case 'default':
    default:
      classes = ButtonClasses;
      break;
  }

  if (!disabled) {
    return classes.concat(additionalClasses).join(' ');
  }

  return classes.concat(DisabledClasses).concat(additionalClasses).join(' ');
};

const Button = props => (
  <button
    className={getButtonClasses('default', props.disabled, props.extraClasses)}
    onClick={props.onClick}
    disabled={props.disabled}
  >
    {props.children}
  </button>
);

Button.defaultProps = {
  disabled: false,
  onClick: () => {},
};

export const DangerButton = props => (
  <button
    className={getButtonClasses('danger', props.disabled, props.extraClasses)}
    onClick={props.onClick}
    disabled={props.disabled}
  >
    {props.children}
  </button>
);

export default Button;

